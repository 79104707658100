/* NavBar.module.css */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensures the navbar stays on top of other content */
  border-bottom: "1px solid white";
  color: "white";
  display: flex;
  justify-content: space-evenly;
  padding: 0px;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo img {
  height: 75px; /* Adjust based on your logo's size */
  margin-right: 10px;
}
.nav-link {
  cursor: pointer; /* Changes cursor to indicate clickable items */
  color: inherit; /* Inherits color from parent, but you can set a specific color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}
.nav-link:hover {
  color: #de033f; /* Chicago Blue */
}

/* Mobile view */
@media (max-width: 768px) {
  .navLinks {
    display: none;
  }
}
